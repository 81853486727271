import QuestionSearch from "~/components/QuestionSearch";
import { getCommonSliceProps } from "~/external/prismic";
import connectSlice from "~/lib/connectSlice";

function mapSliceToProps(slice) {
  const { primary, items } = slice;

  const props = {
    ...getCommonSliceProps(slice),
  };

  return props;
}

export default connectSlice(mapSliceToProps)(QuestionSearch);
