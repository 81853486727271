import Link from "next/link";
import { useState } from "react";
import Block, { BlockContent } from "~/components/Block";
import ButtonSquare from "~/components/ButtonSquare";
import FormInputText from "~/components/FormInputText";
import { CommonSliceProps } from "~/external/prismic.types";
import { prismic } from "~/external/v2/prismic";
import { QuestionDocument, QuestionDocumentContent } from "~/external/v2/types";
import useFormInput from "~/hooks/useFormInput";

// TODO: Extends getCommonSliceProps
type QuestionSearchProps = CommonSliceProps & {};

export default function QuestionSearch(props: QuestionSearchProps) {
  const { spacingTop = 0, spacingBottom = 6, color, backgroundColor } = props;

  const searchInput = useFormInput<string>("");
  const [working, setWorking] = useState(false);
  const [result, setResult] = useState<{
    input: string;
    items: QuestionDocument[];
  } | null>(null);
  const doSearch = async () => {
    setWorking(true);
    const query = searchInput.value?.trim();
    if ((query?.length ?? 0) > 2) {
      const documents = await prismic.question.fullText(query);
      // console.log(documents);
      setResult({ input: searchInput.value, items: documents });
    } else {
      setResult(null);
    }
    setWorking(false);
  };

  const renderDescription = (content: QuestionDocumentContent) => {
    let description: string = null;

    if (content.brief?.match(new RegExp(result.input, "i"))) {
      description = content.brief ?? "";
    }

    if (!description) {
      const blocks =
        content.body?.filter(
          (block) =>
            block.type === "paragraph" &&
            block.text?.match(new RegExp(result.input, "i"))
        ) ?? [];

      description = (blocks.length === 0 ? content.body : blocks)
        .filter((block) => block.type === "paragraph")
        .map((block) => block.text)
        .filter((text) => !!text && text.length > 0)
        .join(" ");
    }

    return `${description.substring(0, 500)}...`;
  };

  return (
    <Block
      spacingTop={spacingTop}
      spacingBottom={spacingBottom}
      backgroundColor={backgroundColor}
      color={color}
    >
      <BlockContent
        style={{
          display: "grid",
          gridTemplateColumns: "4fr 1fr",
          gridGap: "1rem",
        }}
      >
        <FormInputText
          type="text"
          name="input"
          label="What are you looking for?"
          required={false}
          inline
          onKeyUp={(e: React.KeyboardEvent) => {
            // Enter
            if (e.keyCode === 13) {
              doSearch();
            }
          }}
          {...searchInput}
        />
        <ButtonSquare
          onClick={doSearch}
          disabled={working}
          working={working}
          style={{ width: "100%", height: "100%" }}
        >
          Search
        </ButtonSquare>
      </BlockContent>
      {result?.items.length > 0 && (
        <BlockContent style={{ display: "grid", gridGap: "0.2rem" }}>
          {result.items.map((item) => (
            <div key={item.id}>
              <Link href={`/help/${item.content.category.slug}/${item.uid}`} passHref>
                <a style={{ textDecoration: "none", color: "#262626" }}>
                  <h3 style={{ textDecoration: "underline" }}>{item.content.title}</h3>
                  <p style={{ fontSize: "14px" }}>{renderDescription(item.content)}</p>
                </a>
              </Link>
            </div>
          ))}
        </BlockContent>
      )}
    </Block>
  );
}
